<template>
  <Layout contentClass="lesson">
    <div v-if="loading.course" class="items-center justify-center flex-1">
      <Loading />
    </div>
    <div class="lesson__page" v-else>
      <LessonContent :gotoLesson="gotoLesson" />
      <LessonNav
        :gotoLesson="gotoLesson"
        :opened="navOpened"
        :collapsable="layout.breakpoint <= 2"
        @close="navOpened = false"
      />
    </div>
    <div class="lesson__footer" v-if="layout.breakpoint <= 2">
      <div class="lesson__footer__content">
        <ButtonPlay
          :class="{
            'module-toggle': true,
            'module-toggle--full': layout.breakpoint == 1,
          }"
          @click="navOpened = !navOpened"
        >
          <IconSax v-if="navOpened" name="close-circle" />
          <IconSax v-if="!navOpened" name="book" type="bold" />
          {{ navOpened ? $t("my_courses.close_lesson") : $t("my_courses.module_class") }}
        </ButtonPlay>
      </div>
    </div>
  </Layout>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    LessonContent: () => import("@/components/lesson/LessonContent.vue"),
    LessonNav: () => import("@/components/lesson/LessonNav.vue"),
  },
  computed: {
    ...mapGetters({
      layout: "layout/getLayout",
      isMobile: "layout/getIsMobile",
      loading: "courses/getLoading",
    }),
  },
  data() {
    return {
      navOpened: false,
      selectedCertificate: {},
    };
  },
  methods: {
    ...mapActions({
      clear: "courses/actionClear",
      actionDefineMainColor: "config/actionDefineMainColor",
      setSidebarCollapsable: "layout/actionSetSidebarCollapsable",
      setSidebarLocked: "layout/actionSetSidebarLocked",
      openLesson: "courses/actionOpenLesson",
      actionCurrentLessonOpened: "courses/actionCurrentLessonOpened",
    }),

    async load() {
      const { courseId, moduleId, lessonId } = this.$route.params;
      this.openLesson({ courseId, moduleId, lessonId });
    },

    async gotoLesson(goto) {
      const params = await this.openLesson(goto);

      if (this.layout.breakpoint === 1 && goto.type === 2) {
        this.navOpened = false;
      }

      await this.$router.push({
        name: "course-lesson",
        params,
      });
    },
  },
  watch: {
    "layout.breakpoint"() {
      this.navOpened = this.layout.breakpoint > 2;
    }
  },
  beforeMount() {
    this.load();
    this.setSidebarCollapsable(true);
    this.setSidebarLocked(true);
  },
  beforeDestroy() {
    this.clear();
    this.setSidebarLocked(false);
  },
};
</script>
<style lang="scss" scoped>
::v-deep .lesson {
  display: flex;
  flex-direction: column;
}

.lesson__page {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
}

.lesson__footer {
  height: 4rem;
}

.lesson__footer__content {
  position: fixed;
  bottom: 0;
  display: flex;
  z-index: 998;
  width: 100%;
  padding: 0.625rem 2.25rem;
  flex-direction: row;
  justify-content: center;
  gap: 0.625rem;
  border-top: 1px solid var(--bordercolor2);
  background: var(--backgroundcolor);
}

.module-toggle {
  width: 20rem;

  &--full {
    width: 100% !important;
  }
}
</style>
